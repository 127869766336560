jQuery($ => {
  $(document).ready(function() {
    var scrollTop = 0;
    $(window).scroll(function() {
      scrollTop = $(window).scrollTop();
      $(".counter").html(scrollTop);

      if (scrollTop >= 10) {
        $("#nav").addClass("nav-light");
        $("#nav").removeClass("nav-transparent");
      } else if (scrollTop < 10) {
        $("#nav").removeClass("nav-light");
        $("#nav").addClass("nav-transparent");
      }
    });
  });
});

// Accordiation
jQuery(document).ready($ => {
  $(".as-accordion-head").click(function() {
    $(this)
      .find(".as-accordion-close")
      .toggleClass("open");
    $(this)
      .next(".as-accordion-collapse")
      .slideToggle("fast", () => {
        // Do something maybe ..
      });
  });
});
